<template>
  <v-card
    class="reportCardClass cardContent"
    max-width="368"
    max-height="255"
    min-width="368"
    min-height="255"
    :ripple="false"
    @click="newContactHandler(data)"
  >
    <v-col class="pa-0">
      <v-row class="px-5 pt-4 pb-1">
        <v-col
          v-if="data.RegistrationDate"
          cols="12"
          class="pb-0 textDecorationBold font14"
          align="left"
        >
          {{ timeAgo }}
        </v-col>
      </v-row>
      <v-row class="px-5 pb-4">
        <v-col v-if="!data.creator_data" class="pt-0 font14" align="left">
          {{ createdDateString }}
        </v-col>
        <v-col v-else class="pt-0 font14" align="left">
          <v-row>
            <v-col
              v-on:click.stop="registerHandler(data.creator_data._id)"
              class="flex-grow-0 text-no-wrap"
            >
              <span>
                {{ dateStringGenerate }}
                <span
                  style="
                    cursor: pointer;
                    color: green;
                    text-decoration: underline;
                  "
                >
                  {{ ProfileLinkGenerate }}
                </span>
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="py-2 px-4">
        <v-col cols="12" class="pt-5">
          <AvatarField
            :data="getPhoto(data)"
            :isImg="data.isImg ? true : false"
            :showName="true"
            :subNameContent="data.Role ? data.Role : data.Type[0]"
            :nameContent="
              (data.FirstName ? data.FirstName + ' ' : '') +
              (data.LastName ? data.LastName : '')
            "
            fontSizeName="16px"
            :size="40"
            nameContentClass="textDecorationBold primaryColor"
            subNameContentClass="pt-0 pl-3 textDecoration font14 cusTab"
            fontSizeImg="16px"
            backgroundColor="#77BD85"
          />
        </v-col>
        <v-col>
          <v-row class="flex-nowrap pl-2 pb-3">
            <v-col class="flex-grow-0" align="left">
              <v-img
                size="32"
                :src="require('@/assets/realEstateSilder/floor.png')"
              />
            </v-col>
            <v-col class="pl-1 align-self-center text-no-wrap" align="left">
              <v-row>
                <v-col class="pb-0 pt-0 pl-1">
                  <span class="font13" style="color: #263573">
                    {{
                      data.company_name.split(" ")[0] != undefined
                        ? data.company_name.split(" ")[0]
                        : ""
                    }}</span
                  >
                  <span class="font13" style="color: #e52628">
                    {{
                      data.company_name.substring(
                        data.company_name.indexOf(" ") + 1
                      ) != undefined
                        ? data.company_name.substring(
                            data.company_name.indexOf(" ") + 1
                          )
                        : ""
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider />
      <v-row class="px-6 py-4">
        <v-col :class="mobileBreakpoint == true ? '' : 'flex-grow-0'">
          <v-btn
            :width="mobileBreakpoint == true ? '100%' : 81"
            :height="25"
            color="success"
            class="approveClass"
            :loading="acceptLoader"
            @click.stop="approveAction(data._id, true)"
          >
            {{ $t("approve") }}
          </v-btn>
        </v-col>
        <v-col
          align="left"
          :class="mobileBreakpoint == true ? '' : 'flex-grow-0'"
        >
          <v-btn
            :width="mobileBreakpoint == true ? '100%' : 81"
            :height="25"
            color="error"
            class="rejectClass"
            :loading="rejectLoader"
            @click.stop="approveAction(data._id, false)"
          >
            {{ $t("reject") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>
/* 
  Name:ApprovalCard
  Description: ApprovalCard component to generate quickly approval cards for the requests to create accounts.
  API Property   
    data: Object for the data
    createdDate: String, Number for the created date
    openProfile: Function on profile open
    openNewProfile: Function on open new profile
    approveAction: Function on approve action
    acceptLoader: Boolean for accept loader
    rejectLoader: Boolean for reject loader

  Components Used:
    AvatarField
*/
import AvatarField from "@/components/Fields/AvatarField/AvatarField.vue";
import i18n from "@/i18n/i18n";
import CoreHelper from "@/helpers/Core/core.js";
export default {
  name: "ApprovalCard",
  props: {
    data: Object,
    createdDate: [String, Number],
    openProfile: Function,
    openNewProfile: Function,
    approveAction: Function,
    acceptLoader: Boolean,
    rejectLoader: Boolean,
  },
  components: { AvatarField },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    createdDateString() {
      return isNaN(this.data.RegistrationDate)
        ? this.data.RegistrationDate
        : CoreHelper.EpochToDate(this.data.RegistrationDate) +
            " " +
            i18n.t("footerP3") +
            " Register Page";
    },

    dateStringGenerate() {
      return isNaN(this.data.RegistrationDate)
        ? this.data.RegistrationDate
        : CoreHelper.EpochToDate(this.data.RegistrationDate) +
            " " +
            i18n.t("footerP3");
    },

    ProfileLinkGenerate() {
      return isNaN(this.data.RegistrationDate)
        ? this.data.RegistrationDate
        : " " +
            this.data.creator_data.FirstName +
            " " +
            this.data.creator_data.LastName;
    },

    timeAgo() {
      try {
        return CoreHelper.time_ago_card(this.data.RegistrationDate);
      } catch {
        return "";
      }
    },
  },
  methods: {
    registerHandler(id) {
      this.openProfile(id);
    },
    newContactHandler(data) {
      this.openNewProfile(data);
    },
    creatorHandler(id, event) {
      event.stopPropagation();
      this.openProfile(id);
    },
    getPhoto(obj) {
      if (![undefined, "", null].includes(obj.img)) {
        return obj.img;
      } else {
        if (obj.FirstName && obj.LastName) {
          return obj.FirstName.charAt(0) + obj.LastName.charAt(0);
        } else if (obj.FirstName) {
          return obj.FirstName.charAt(1);
        } else if (obj.LastName) {
          return obj.LastName.charAt(1);
        }
        return "";
      }
    },
  },
};
</script>
<style scoped>
.cardContent {
  margin: 10px 12px 10px 10px;
}
.approveClass {
  font-size: 13px;
  background-color: #77bd85 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
}

.rejectClass {
  font-size: 13px;
  background-color: #e52628 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
}
</style>