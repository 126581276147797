<template>
  <v-col>
    <v-row>
      <v-col align="left">
        <span class="textDecorationBold font16">
          {{ $t("approvingScreen") }}
        </span>
      </v-col>
      <v-col class="flex-grow-0 pb-0 pr-0">
        <v-pagination
          class="main-pgt"
          v-model="options.page"
          :total-visible="6"
          :length="totalPages"
          @input="fetchContacts"
        >
        </v-pagination>
      </v-col>
    </v-row>
    <v-row
      class="noScrollBar contactsCards mb-6"
      :style="
        mobileBreakpoint != true
          ? 'min-height: 270px !important; max-height: ' +
            getTableHeight +
            '; overflow: auto;'
          : ''
      "
      :class="
        mobileBreakpoint == true || loading
          ? 'justify-center'
          : data.length > 0
          ? 'justify-start'
          : 'justify-start'
      "
    >
      <LoaderGif v-if="loading" :gray="true" />
      <ApprovalCard
        v-else-if="!loading && dataFoundStatus == 'true'"
        v-for="d in data"
        :data="d"
        :key="d._id"
        :openProfile="openProfile"
        :openNewProfile="openNewProfile"
        :approveAction="approveAction"
        :acceptLoader="acceptLoader"
        :rejectLoader="rejectLoader"
      />
      <v-col v-if="!loading && this.dataFoundStatus == 'false'">
        <v-row>
          <v-col align="center" class="pb-0">
            <v-img
              :src="require('@/assets/general/notRightSearch.png')"
              height="274"
              width="327"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="primaryColor textDecorationBold font20">{{
              $t("nothingHere")
            }}</span>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="!loading && this.dataFoundStatus == 'error'">
        <v-row>
          <v-col align="center" class="pb-0">
            <v-img
              :src="require('@/assets/general/serverProblem.png')"
              height="209"
              width="399"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="primaryColor textDecorationBold font20">{{
              $t("serverProblem")
            }}</span>
          </v-col>
          <v-col cols="12 pt-0">
            <span class="primaryColor textFontDecoration font14">{{
              $t("pageRefresh")
            }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="">
            <v-btn
              class="btn-custom"
              @click="openEmail"
              key="fBtn0"
              outlined
              color="primary"
            >
              {{ $t("sendError") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-footer
      inset
      padless
      absolute
      style="background-color: #ebebeb"
      v-if="data.length > 0"
      :class="mobileBreakpoint == true ? 'pb-10' : ''"
    >
      <v-row class="mb-0" style="max-width: 100% !important">
        <v-col align="left" class="main-pgt pt-4 footerCl">
          {{ $t("footerP1") }} {{ options.page ? options.page : 0 }}
          {{ $t("footerP2") }} {{ options.items ? options.items : 0 }}
          {{ $t("footerP3") }} {{ totalResult ? totalResult : 0 }}
          {{ $t("footerP4") }}
        </v-col>
      </v-row>
    </v-footer>

    <ContactSlider
      v-if="dialog.type == 'profileSlider' && dialog.open"
      :data="dialog.data"
      :closeHandler="closeDialog"
      :editProfile="true"
      :updateIsSavedHandler="() => true"
      :openEditSlider="() => (dialog.type = 'editContactSlider')"
    />
    <EditSlider
      v-if="dialog.type == 'editContactSlider' && dialog.open"
      :editData="dialog.data"
      :closeHandler="closeDialog"
      :closeEditDialog="closeEditDialog"
      :fetchSpecificContact="fetchContacts"
    />
  </v-col>
</template>

<script>
/* 
  Name: Accounts
  Description: Accounts view is a page for aprrove/decline accounts from the secretary.
  
  Components Used:
    HeaderTitle

  Endpoints Functions Called:
  -ContactsRepo
    editContact
    get
    getId
*/
import ApprovalCard from "@/components/Secretary/ApprovalProcess/ApprovalCard.vue";
import ContactSlider from "@/components/Contacts/ContactSlider/ContactSlider";
import Repository from "@/services/Repository";
import ContactsHelper from "@/helpers/Contacts/contacts.js";
import ContactsModel from "@/static/contacts/contacts.js";
import EditSlider from "@/components/Contacts/EditContact/EditSlider.vue";
import LoaderGif from "@/components/Popups/LoaderGif";

const ContactsRepo = Repository.get("contacts");

export default {
  name: "Accounts",
  components: { ApprovalCard, ContactSlider, EditSlider, LoaderGif },
  data() {
    return {
      data: [],
      loading: false,
      isRefresh: false,
      acceptLoader: false,
      rejectLoader: false,
      dataFoundStatus: null,
      filterValues: ContactsModel.filterValues,
      filterObject: [],
      totalPages: 0,
      totalResult: 0,
      options: {
        page: 1,
        items: 20,
      },
      dialog: {
        open: false,
        type: null,
        data: null,
      },
    };
  },
  async created() {
    await this.fetchContacts();
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    getTableHeight() {
      return window.innerHeight - 365 + "px";
    },
  },
  methods: {
    async openProfile(id) {
      this.loading = true;
      let params = {
        page: 0,
        items: 1,
      };

      let resp = await ContactsRepo.getId(id, params);

      if (
        resp &&
        resp.data &&
        resp.data.contacts &&
        resp.data.contacts.length
      ) {
        this.openDialog(
          "profileSlider",
          ContactsHelper.initDataContact(resp.data.contacts[0])
        );
      }
    },
    openNewProfile(data) {
      this.openDialog("profileSlider", data);
    },
    async approveAction(id, action) {
      if (action == true) {
        let body = { IsApproved: true };
        try {
          this.acceptLoader = true;
          await ContactsRepo.editContact(id, body);
          this.acceptLoader = false;
        } catch (e) {
          this.acceptLoader = false;
        }
      } else if (action == false) {
        let body = { IsDeleted: true };
        try {
          this.rejectLoader = true;
          await ContactsRepo.editContact(id, body);
          this.rejectLoader = false;
        } catch (e) {
          this.rejectLoader = false;
        }
      }
      this.fetchContacts();
    },
    //  async approveAction(id) {
    //     let body = { IsApproved: true };
    //     try {
    //       this.acceptLoader = true;
    //       await ContactsRepo.editContact(id, body);
    //       this.fetchContacts();
    //       this.acceptLoader = false;
    //     } catch (e) {
    //       this.acceptLoader = false;
    //     }
    //   },

    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
      this.loading = false;
    },
    closeEditDialog() {
      this.fetchContacts();
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },

    async fetchContacts() {
      try {
        //let object = {};

        let object = {
          $and: [
            {
              $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }],
            },
            {
              $or: [{ IsApproved: false }],
            },
            // {
            //   Type: { $in: "Συνεργάτης" },
            // },
          ],
        };

        this.loading = true;
        let params;
        if (this.isRefresh == true) {
          this.options.page = 1;
          this.isRefresh = false;
        }
        params = {
          page: this.options.page - 1,
          items: this.options.items,
        };
        var resp = await ContactsRepo.get(object, params);
        if (resp.data != undefined && resp.data.contacts != undefined) {
          if (resp.data.contacts.length > 0) {
            this.dataFoundStatus = "true";
            this.data = resp.data.contacts.map((c) => {
              return ContactsHelper.initDataContact(c);
            });

            this.totalResult = resp.data.results;
            this.totalPages = Math.ceil(this.totalResult / this.options.items);
            if (this.totalPages == 0) this.totalPages = 1;
          } else {
            this.dataFoundStatus = "false";
            this.data = [];
            this.totalResult = 0;
            this.totalPages = 0;
          }
        }
        this.loading = false;
      } catch (e) {
        this.dataFoundStatus = "error";
        this.loading = false;
        throw e;
      }
    },
  },
};
</script>

<style scoped>
.contactsCards {
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  .contactsCards {
    overflow: unset !important;
  }
}
</style>